import React, { FC, useState } from 'react';
import {
  Button,
  ButtonGroup,
  InputGroup,
  FormGroup,
  TextArea,
} from '@blueprintjs/core';
import { Select, ItemRenderer, ItemListRenderer } from '@blueprintjs/select';
import { DatePicker } from '@blueprintjs/datetime';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';

import './CreateDisruptionFormStyle.css';
import text from '../text';

import { ReactComponent as Blocked } from '../img/disruptions/blocked.svg';
import { ReactComponent as Limited } from '../img/disruptions/limited.svg';
import { ReactComponent as RoadWork } from '../img/disruptions/road_work.svg';

type onCreate = (data: Omit<Disruption, 'location' | 'id'>) => void;
interface CreateDisruptionFormProps {
  onCancel: () => void;
  onCreate: onCreate;
}

const submit = (
  onCreate: onCreate,
  title: string,
  description: string,
  type: DisruptionType,
  start: Date,
  end: Date,
): void => {
  onCreate({
    title,
    description,
    type,
    start,
    end,
  });
};

const DisruptionTypeSelect = Select.ofType<DisruptionType>();

const options: DisruptionType[] = ['road_works', 'limited', 'blocked'];

const getIcon = (type: string): JSX.Element | undefined => {
  switch (type) {
    case 'road_works':
      return <RoadWork />;
    case 'limited':
      return <Limited />;
    case 'blocked':
      return <Blocked />;
  }

  return undefined;
};

const renderDisruptionTypeList: ItemListRenderer<DisruptionType> = ({
  items,
  itemsParentRef,
  query,
  renderItem,
}) => {
  return <div className="disruption-type-list">{items.map(renderItem)}</div>;
};

const renderDisruptionType: ItemRenderer<string> = (
  item,
  { handleClick, modifiers },
) => {
  return (
    <Button
      className="DisruptionTypeListItem"
      key={item}
      icon={getIcon(item)}
      onClick={handleClick}
    />
  );
};

const CreateDisruptionForm: FC<CreateDisruptionFormProps> = ({
  onCancel,
  onCreate,
}) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [type, setType] = useState<DisruptionType>('road_works');
  const [start, setStart] = useState<Date>(new Date());
  const [end, setEnd] = useState<Date>(moment().add(7, 'd').toDate());
  return (
    <div className="Bubble CreateForm">
      <div className="BubbleContent">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (title.length > 2) {
              submit(onCreate, title, description, type, start, end);
            }
          }}
        >
          <ButtonGroup vertical fill>
            <FormGroup
              label={text('title')}
              labelInfo={text('required', false)}
            >
              <InputGroup
                leftIcon="tag"
                value={title}
                //Following disable because onChange typing seems wrong, it claims 'ev' is any.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                onChange={(ev: any) => setTitle(ev.target.value)}
              />
            </FormGroup>
            <FormGroup
              label={text('description')}
              labelInfo={text('required', false)}
            >
              <TextArea
                value={description}
                className="bp3-fill"
                style={{ width: '100%' }}
                //Following disable because onChange typing seems wrong, it claims 'ev' is any.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                onChange={(ev: any) => setDescription(ev.target.value)}
              />
            </FormGroup>
            <FormGroup label={text('type')} labelInfo={text('required', false)}>
              <DisruptionTypeSelect
                filterable={false}
                items={options}
                onItemSelect={(item) => setType(item)}
                itemRenderer={renderDisruptionType}
                itemListRenderer={renderDisruptionTypeList}
                popoverProps={{
                  targetClassName: 'disruption-popover-target',
                  position: 'bottom',
                }}
              >
                <Button
                  className="disruption-type-btn"
                  alignText="left"
                  fill
                  icon={getIcon(type)}
                  text={text(type)}
                  rightIcon="double-caret-vertical"
                />
              </DisruptionTypeSelect>
            </FormGroup>
            <ButtonGroup fill>
              <FormGroup
                label={text('start')}
                labelInfo={text('required', false)}
              >
                <DatePicker
                  value={start}
                  localeUtils={MomentLocaleUtils}
                  locale="sv-SE"
                  maxDate={moment().add(5, 'years').toDate()}
                  minDate={moment().add(-1, 'years').toDate()}
                  timePrecision="minute"
                  canClearSelection={false}
                  onChange={(new_start) => {
                    setStart(new_start);
                  }}
                />
              </FormGroup>
              <FormGroup
                label={text('end')}
                labelInfo={text('required', false)}
              >
                <DatePicker
                  value={end}
                  localeUtils={MomentLocaleUtils}
                  locale="sv-SE"
                  maxDate={moment().add(5, 'years').toDate()}
                  minDate={moment().add(-1, 'years').toDate()}
                  timePrecision="minute"
                  canClearSelection={false}
                  onChange={(new_end) => {
                    setEnd(new_end);
                  }}
                />
              </FormGroup>
            </ButtonGroup>
          </ButtonGroup>
          <ButtonGroup fill>
            <Button intent="warning" type="button" onClick={() => onCancel()}>
              {text('cancel')}
            </Button>
            <Button
              intent="primary"
              type="button"
              disabled={title.length < 3}
              onClick={() =>
                submit(onCreate, title, description, type, start, end)
              }
            >
              {text('create')}
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
};

export default CreateDisruptionForm;
