import React, { FC } from 'react';
import { Text } from '@blueprintjs/core';

import './HelpTextStyle.css';
import text from '../text';
import { ReactComponent as IconRoadWorks } from '../img/disruptions/road_work.svg';

interface HelpTextProps {
  /** If true the button will be enabled. */
  enabled: boolean;
  /** Called when user clicks the button. */
  onOpenCreate: () => void;
}

const HelpText: FC<HelpTextProps> = ({ enabled, onOpenCreate }) => {
  return (
    <div className="Bubble">
      <div className="BubbleHeader">
        <p className="BubbleTitle">Störningar</p>
      </div>
      <div className="BubbleContent">
        <IconRoadWorks className="BubbleImage" />
        <Text className="PreWrap">{text('pick_location_help_text')}</Text>
        <br />
        <button
          className="Btn FullWidth"
          disabled={!enabled}
          onClick={onOpenCreate}
        >
          <p>{text('create_disruption')}</p>
        </button>
      </div>
    </div>
  );
};

export default HelpText;
