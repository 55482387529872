import React, { FC, useState } from 'react';
import { Button, InputGroup } from '@blueprintjs/core';
import { executeAddressSearch } from './search_funcs';

import text from '../text';

const doSingleSearch = (
  map_funcs: ExportedFuncs,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  setSearchResultPath: React.Dispatch<
    React.SetStateAction<SearchResultsForMap | undefined>
  >,
  input: string,
): void => {
  executeAddressSearch(map_funcs, input)
    .then((res) => {
      setValue(res.address);
      if (res.location) {
        setSearchResultPath({
          poi_one: res.location,
        });
      } else {
        setSearchResultPath({});
      }
    })
    .catch((err) => {
      if (err === 'Empty.') {
        console.warn('Address search gave zero results.');
        return;
      }
      throw err;
    });
};

interface SingleInputSearchProps {
  map_funcs: ExportedFuncs;
  onClearSearch: () => void;
  setSearchResultPath: React.Dispatch<
    React.SetStateAction<SearchResultsForMap | undefined>
  >;
}
const SingleInputSearch: FC<SingleInputSearchProps> = ({
  onClearSearch,
  map_funcs,
  setSearchResultPath,
}) => {
  const [value, setValue] = useState<string>('');
  return (
    <div className="AddressSearch">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (value.length > 0) {
            doSingleSearch(map_funcs, setValue, setSearchResultPath, value);
          }
        }}
      >
        <div className="FormRow">
          <InputGroup
            fill
            placeholder={text('address')}
            leftIcon="globe"
            value={value}
            large
            //Following disable because onChange typing seems wrong, it claims 'ev' is any.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            onChange={(ev: any) => setValue(ev.target.value)}
            rightElement={
              <Button
                icon="delete"
                className="BtnReset"
                disabled={value.length < 2}
                onClick={() => {
                  onClearSearch();
                  setValue('');
                }}
              />
            }
          />
          <button
            className="Btn SearchPerformSearch"
            type="submit"
            disabled={value.length < 2}
          >
            <p>{text('find')}</p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SingleInputSearch;
