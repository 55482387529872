import React, { FC, useState, useEffect } from 'react';
import WrappedMapBase, {
  GMW_WrappedMarker,
  GMW_LatLngLiteral,
} from 'google-maps-wrapper';
import withConfig from 'with-config';
import { Config } from '../config';

import './MapStyle.css';

import {
  getDisruptionIcon,
  getEmptyIcon,
  getSearchResultMarkerIcon,
} from './styles_and_markers';

interface ExternalFuncs {
  setCenter: (latlng: GMW_LatLngLiteral) => void;
}
type OnMarkerClick = (id: string | number) => void;
interface Props {
  init_cb?: (map_funcs: ExportedFuncs, additional_funcs: ExternalFuncs) => void;
  url_route: AppRoute;
  /** Disruption state. */
  disruptions: Disruption[];
  onMarkerClick: OnMarkerClick;
  setCreateLocation: (location: GMW_LatLngLiteral) => void;
  search_result_path?: SearchResultsForMap;
  selected_disruption_id?: number;
  onMapClick: () => void;
}

interface SearchResultObjects {
  marker_one: GMW_WrappedMarker | null;
}

interface MarkerContainer {
  [id: string]: GMW_WrappedMarker;
}

/** This is where new markers are created before they are assigned state data. */
const DEFAULT_CREATION_POSITION: GMW_LatLngLiteral = { lat: 55.7, lng: 13.6 };

//-------------------------------------------------------
//-------------------------------------------------------
/////DISRUPTION LAYER
/////

const updateDisruptionMarkers = (
  map: ExportedFuncs,
  url_route: AppRoute,
  disruptions: Disruption[],
  marker_container: MarkerContainer,
  onMarkerClick: OnMarkerClick,
  selected_disruption_id?: number,
): void => {
  const unupdated_ids = new Set(Object.keys(marker_container));
  disruptions.forEach((disruption) => {
    unupdated_ids.delete(disruption.id.toString());
    getMarker(map, disruption.id.toString(), marker_container, onMarkerClick)
      .then((marker) => {
        marker.show();
        return marker.setOptions({
          default: {
            position: disruption.location,
            icon: getDisruptionIcon(
              selected_disruption_id === disruption.id,
              disruption.type,
            ),
          },
        });
      })
      .catch((err) => {
        throw err;
      });
  });
  //Any unupdated_ids that are left no longer exist in the given state, so we remove them.
  unupdated_ids.forEach((id) => {
    marker_container[id].remove();
    delete marker_container[id];
  });
};

/////
/////END DISRUPTIONS LAYER
//-------------------------------------------------------
//-------------------------------------------------------
/////CREATION MARKER LAYER
/////

const updateCreationMarker = async (
  map: ExportedFuncs,
  search_result_objects: SearchResultObjects,
  setCreateLocation: (loc: GMW_LatLngLiteral) => void,
  position?: GMW_LatLngLiteral,
): Promise<void> => {
  if (!search_result_objects.marker_one) {
    search_result_objects.marker_one = await map.setMarker(
      'search_result_marker_one',
      {
        default: {
          visible: false,
          position: DEFAULT_CREATION_POSITION,
          icon: getSearchResultMarkerIcon(),
          draggable: true,
        },
      },
    );
  }

  const { marker_one } = search_result_objects;
  if (position) {
    setCreateLocation(position);
    marker_one.show();
    marker_one.registerEventCB('dragend', (e: google.maps.MouseEvent) => {
      setCreateLocation(e.latLng.toJSON());
    });
    marker_one
      .setOptions({
        default: {
          position: position,
          icon: getSearchResultMarkerIcon(),
          draggable: true,
        },
      })
      .catch((err) => {
        throw err;
      });
  } else {
    marker_one.hide();
  }
};

/////
/////END SEARCH RESULTS
//-------------------------------------------------------
//-------------------------------------------------------
/////OTHER FUNCS
/////

const getMarker = async (
  map: ExportedFuncs,
  id: string,
  marker_container: MarkerContainer,
  onMarkerClick: OnMarkerClick,
): Promise<GMW_WrappedMarker> => {
  marker_container[id] =
    marker_container[id] ||
    (await map.setMarker('disruption_' + id, {
      default: {
        visible: false,
        position: DEFAULT_CREATION_POSITION,
        icon: getEmptyIcon(),
      },
    }));

  marker_container[id].registerEventCB('click', () => {
    onMarkerClick(parseInt(id, 10));
  });
  return marker_container[id];
};

const onMapInitialized = (
  map: ExportedFuncs,
  setWrappedMap: React.Dispatch<React.SetStateAction<ExportedFuncs | null>>,
  init_cb?: (map_funcs: ExportedFuncs, additional_funcs: ExternalFuncs) => void,
): void => {
  setWrappedMap(map);

  init_cb &&
    init_cb(map, {
      setCenter: (latlng) => setCenter(map, latlng),
    });
};

const setCenter = (map: ExportedFuncs, latlng: GMW_LatLngLiteral): void => {
  map.setCenter(latlng).catch((err) => {
    throw err;
  });
};

/////////////////////////////
/////////////////////////////

const Map: FC<Props> = ({
  disruptions,
  init_cb,
  onMarkerClick,
  url_route,
  search_result_path,
  selected_disruption_id,
  setCreateLocation,
  onMapClick,
}) => {
  const [wrapped_map, setWrappedMap] = useState<ExportedFuncs | null>(null);
  const [marker_container] = useState<MarkerContainer>({});
  const [search_result_objects] = useState<SearchResultObjects>({
    marker_one: null,
  });
  const [new_marker_pos, setNewMarkerPos] = useState<
    GMW_LatLngLiteral | undefined
  >(undefined);

  useEffect(() => {
    if (!wrapped_map) {
      return;
    }
    updateDisruptionMarkers(
      wrapped_map,
      url_route,
      disruptions,
      marker_container,
      onMarkerClick,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disruptions, selected_disruption_id, wrapped_map]);

  useEffect(() => {
    if (!wrapped_map) {
      return;
    }
    setNewMarkerPos(search_result_path && search_result_path.poi_one);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search_result_path, wrapped_map]);
  useEffect(() => {
    if (!wrapped_map) {
      return;
    }
    updateCreationMarker(
      wrapped_map,
      search_result_objects,
      setCreateLocation,
      new_marker_pos,
    ).catch((err) => {
      throw err;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [new_marker_pos, wrapped_map]);

  /////
  /////end USE EFFECTS

  const config: Config = withConfig.getCurrentConfig();
  return (
    <div className="MapContainer">
      <WrappedMapBase
        googleapi_maps_uri="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,places,drawing&key=AIzaSyA0tp0r6ImLSnn9vy4zXjZWar1F3U5eOaY"
        default_center={config.map.center}
        default_zoom={config.map.zoom}
        initializedCB={(map_ref, funcs) => {
          onMapInitialized(funcs, setWrappedMap, init_cb);
        }}
        styles={config.map.style}
        onClick={(e: google.maps.MouseEvent) => {
          const loc = e.latLng.toJSON();
          onMapClick();
          setNewMarkerPos(loc);
        }}
      />
    </div>
  );
};

export default Map;
