export type Action =
  | { type: 'update'; payload: Disruption[] }
  | { type: 'add'; payload: Disruption }
  | { type: 'remove'; payload: number[] };

const reducer = (state: DisruptionState, action: Action): DisruptionState => {
  switch (action.type) {
    case 'update': {
      const payload = action.payload;
      const all = [...state.all];
      const by_id = Object.assign({}, state.by_id);

      payload.forEach((dis) => {
        by_id[dis.id] = dis;
        const index = all.findIndex((d) => d.id === dis.id);
        if (index > -1) {
          all[index] = dis;
        } else {
          all.push(dis);
        }
      });

      return {
        all,
        by_id,
      };
    }

    case 'add': {
      const payload = action.payload;
      if (state.by_id[payload.id]) {
        //This disruption already exists.
        //TODO: What do?
        return state;
      }
      return {
        all: [...state.all, payload],
        by_id: Object.assign({}, state.by_id, { [payload.id]: payload }),
      };
    }
    case 'remove': {
      const id_arr = action.payload;

      const all = [...state.all];
      const by_id = Object.assign({}, state.by_id);

      id_arr.forEach((id) => {
        const index = state.all.findIndex((dis) => dis.id === id);
        if (index > -1) {
          all.splice(index, 1);
        }
        delete by_id[id];
      });

      return {
        all,
        by_id,
      };
    }
    default:
      return state;
  }
};

export default reducer;
