export const convertDisruptionFromApi = (
  api_dis: DisruptionFromApi,
): Disruption => {
  return {
    description: api_dis.description,
    end: new Date(api_dis.end),
    id: api_dis.id,
    location: api_dis.location,
    start: new Date(api_dis.start),
    title: api_dis.title,
    type: api_dis.type,
  };
};
