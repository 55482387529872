/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
//Above because of weird typings for google maps geo svc.

import text from '../text';

const addressLookup = (
  map_funcs: ExportedFuncs,
  input: string,
): Promise<AddressLookupResult> => {
  const geo_svc = map_funcs.getServices().geocoderService;

  return new Promise((resolve, reject) => {
    geo_svc.geocode(
      { address: input, componentRestrictions: { country: 'SE' } },
      (res, status) => {
        if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          reject('Empty.');
        }
        if (status !== google.maps.GeocoderStatus.OK) {
          return reject('Geocoding request failed.');
        }
        console.log('INFO: Goecoding request to google has finished.');
        if (res.length === 0 || res[0].formatted_address.length === 0) {
          resolve({ input: input, address: text('invalid_address') });
        } else {
          resolve({
            input: input,
            address: res[0].formatted_address,
            location: res[0].geometry.location.toJSON(),
            viewport: res[0].geometry.viewport.toJSON(),
          });
        }
      },
    );
  });
};

export const executeAddressSearch = async (
  map_funcs: ExportedFuncs,
  input: string,
): Promise<AddressLookupResult> => {
  const address = await addressLookup(map_funcs, input);
  if (address.location) {
    map_funcs.setCenter(address.location).catch((err) => {
      throw err;
    });
  }
  return Promise.resolve(address);
};
