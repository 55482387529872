import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import oh from 'output-helpers';
import withConfig from 'with-config';
import './reset.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import './global.css';
import { dictionary } from './text';
import MainPanel from './MainPanel';
import Auth from './Auth';
import default_config from './config';

withConfig.setDefault(default_config);

oh.setConfig({
  lang: 'sv-SE',
  fallback_language: 'en-US',
  date_locale: 'sv-SE',
  dictionaries: [dictionary],
});

ReactDOM.render(
  <Router>
    <Auth>
      <div className="AppBase">
        <Route
          exact
          path={['/', '/disruptions', '/disruption/:disruption_id']}
          component={MainPanel}
        />
      </div>
    </Auth>
  </Router>,
  document.getElementById('root'),
);
