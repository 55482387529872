import {
  dictionary,
  TextKeys as TextKeys2,
  translate,
  Dictionary as Dictionary2,
} from './text';

export { dictionary };
export type TextKeys = TextKeys2;
export type Dictionary = Dictionary2;
export default translate;
