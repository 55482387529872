import React, { FC, useState, useEffect, useReducer } from 'react';
import { RouteChildrenProps, match } from 'react-router';
import H from 'history';

import './MainPanelStyle.css';

import disruptionReducer from './disruption_state';
import * as ws from '../disruption_notifications/ws';

import Map from '../Map';
import Search from '../Search';
import { GMW_ExportedFunctions, GMW_LatLngLiteral } from 'google-maps-wrapper';
import CreateDisruptionForm from '../CreateDisruptionForm';
import HelpText from '../HelpText';
import DisruptionDetails from '../DisruptionDetails';
import withConfig from 'with-config';
import EditDisruptionForm from '../EditDisruptionForm';

interface Props extends RouteChildrenProps {
  match: match<{ disruption_id: string }>;
}

const extractRouteFromUrl = (location: H.Location): AppRoute => {
  if (location.pathname.startsWith('/disruption')) {
    return 'disruption';
  }
  return 'disruptions';
};

const onMarkerClick = (id: string | number, history: H.History): void => {
  history.push('/disruption/' + id.toString());
};

const editDisruption = (data: Disruption): void => {
  const to_update: DisruptionFromApi = Object.assign({}, data, {
    start: data.start.toISOString(),
    end: data.end.toISOString(),
  });
  ws.editDisruption(to_update);
};

const createDisruption = (data: Omit<Disruption, 'id'>): void => {
  const new_disruption: Omit<DisruptionFromApi, 'id'> = Object.assign(
    {},
    data,
    {
      start: data.start.toISOString(),
      end: data.end.toISOString(),
    },
  );
  ws.addDisruption(new_disruption);
};
const removeDisruption = (id: number): void => {
  ws.removeDisruption([id]);
};

const MainPanel: FC<Props> = ({ match, location, history }) => {
  const [current_route, setCurrentRoute] = useState<AppRoute>('disruptions');
  const [show_form, setShowForm] = useState<boolean>(false);
  const [show_edit_form, setShowEditForm] = useState<boolean>(false);
  const [selected_disruption_id, setSelectedDisruptionId] = useState<
    number | undefined
  >(undefined);
  const [search_result_path, setSearchResultPath] = useState<
    SearchResultsForMap | undefined
  >(undefined);
  const [map_funcs, setMapFuncs] = useState<GMW_ExportedFunctions | null>(null);
  const [disruption_state, dispatchDisruption] = useReducer(disruptionReducer, {
    all: [],
    by_id: {},
  });
  const [creation_loc, setCreationLoc] = useState<
    GMW_LatLngLiteral | undefined
  >(undefined);

  useEffect(() => {
    // eslint-disable-next-line
    ws.prepare(dispatchDisruption, window.sso.getJWT().getRaw());
    ws.start(withConfig.getCurrentConfig());
  }, []);

  useEffect(() => {
    const new_url_route = extractRouteFromUrl(location);
    if (new_url_route !== current_route) {
      setCurrentRoute(new_url_route);
    }
    if (new_url_route === 'disruption' && !!match.params.disruption_id) {
      const id_in_params = parseInt(match.params.disruption_id, 10);
      if (id_in_params !== selected_disruption_id) {
        setSelectedDisruptionId(id_in_params);
      }
    } else if (selected_disruption_id !== undefined) {
      setSelectedDisruptionId(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <div className="MainPanel">
        <Map
          onMapClick={() => {
            history.push('/');
          }}
          url_route={current_route}
          onMarkerClick={(id: string | number) => {
            onMarkerClick(id, history);
          }}
          disruptions={disruption_state.all}
          init_cb={(funcs) => setMapFuncs(funcs)}
          search_result_path={search_result_path}
          selected_disruption_id={selected_disruption_id}
          setCreateLocation={setCreationLoc}
        />
      </div>

      {map_funcs && (
        <Search
          map_funcs={map_funcs}
          setSearchResultPath={setSearchResultPath}
        />
      )}
      <HelpText
        enabled={!!creation_loc}
        onOpenCreate={() => setShowForm(true)}
      />
      {show_form && (
        <CreateDisruptionForm
          onCancel={() => {
            setShowForm(false);
          }}
          onCreate={(data) => {
            if (creation_loc) {
              createDisruption({ ...data, location: creation_loc });
              setCreationLoc(undefined);
              setSearchResultPath({}); //This will force map to update, becuase it is a new object. It will not work if set to undefined.
              setShowForm(false);
            }
          }}
        />
      )}
      {show_edit_form && selected_disruption_id !== undefined && (
        <EditDisruptionForm
          onCancel={() => {
            setShowEditForm(false);
          }}
          to_edit={disruption_state.by_id[selected_disruption_id]}
          onEdit={(data) => {
            editDisruption(data);
            setShowEditForm(false);
          }}
        />
      )}
      {selected_disruption_id !== undefined &&
        disruption_state.by_id[selected_disruption_id] && (
          <DisruptionDetails
            onRemove={(id: number) => {
              removeDisruption(id);
              history.push('/');
            }}
            disruption={disruption_state.by_id[selected_disruption_id]}
            onEdit={() => {
              setShowEditForm(true);
            }}
          />
        )}
    </>
  );
};

export default MainPanel;
