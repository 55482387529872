/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
//-- THESE DISABLES ARE BECAUSE OF GOOGLE API MISSING SOME TYPE DEFINITIONS --

import IconEmpty from '../img/empty.svg';
import IconRoadWorks from '../img/disruptions/road_work.svg';
import IconLimited from '../img/disruptions/limited.svg';
import IconBlocked from '../img/disruptions/blocked.svg';

import SearchResultPOIMarker from '../img/marker.svg';
import SearchResultPOIWithLabelMarker from '../img/marker.svg';

declare const google: any;

type Icon = {
  url: string;
  labelOrigin?: any;
  anchor?: any;
  scaledSize?: any;
};

export const getEmptyIcon = (): Icon => {
  return {
    url: IconEmpty,
    labelOrigin: new google.maps.Point(6, 6),
    anchor: new google.maps.Point(3, 3),
  };
};

export const getDisruptionIcon = (
  selected: boolean,
  disruption_type: DisruptionType,
): Icon => {
  //TODO: Add disruption type icons.
  //TODO: Add alternative for selected maybe?
  switch (disruption_type) {
    case 'road_works':
      return {
        url: IconRoadWorks,
        anchor: new google.maps.Point(25, 25),
      };
    case 'limited':
      return {
        url: IconLimited,
        anchor: new google.maps.Point(25, 25),
      };
    case 'blocked':
      return {
        url: IconBlocked,
        anchor: new google.maps.Point(25, 25),
      };
    default: {
      throw new Error('Invalid input to getDisruptionIcon: ' + disruption_type);
    }
  }
};

export const getSearchResultMarkerIcon = (with_label = false): Icon => {
  return {
    url: with_label ? SearchResultPOIMarker : SearchResultPOIWithLabelMarker,
    labelOrigin: new google.maps.Point(0, 35),
    anchor: new google.maps.Point(18, 50),
  };
};
