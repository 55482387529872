import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import withConfig from 'with-config';
import SSO from 'sso-helper';
import { authenticate } from './disruption_notifications/ws';
import { Config } from './config';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    sso: any;
  }
}

interface AuthProps {
  config: Config;
}

const AuthCheck: React.FC<AuthProps> = ({ children, config }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (window.sso) {
      return;
    }

    const loginUri =
      config.api.sso.login_uri +
      '/login' +
      '?service=' +
      encodeURIComponent(config.service_name);

    const ssoConfig = {
      login_uri: config.api.sso.login_uri,
      token_provider_uri: config.api.sso.uri,
      jwt_acquire_callback: () => {
        console.log(
          '[JWT] acquire callback called, redirecting to',
          location.pathname,
        );
        history.push(location.pathname);
        setLoggedIn(true);
        // eslint-disable-next-line
        authenticate(window.sso.getJWT().getRaw());
      },
      jwt_renew_callback: () => {
        console.log(`[JWT] new token received, reauthorizing websocket`);
        // eslint-disable-next-line
        authenticate(window.sso.getJWT().getRaw());
      },
      jwt_release_callback: (wasLogout: boolean) => {
        window.sso = null;
        if (!wasLogout) {
          console.log('navigating to login', loginUri);
          document.location.href = loginUri;
        }
      },
    };

    // eslint-disable-next-line
    window.sso = new SSO(config.service_name, ssoConfig);
    // eslint-disable-next-line
    window.sso
      .init()
      .then(() => {
        // eslint-disable-next-line
        if (!window.sso.isLoggedIn()) {
          console.log('navigating to login', loginUri);
          document.location.href = loginUri;
          return;
        }

        setLoggedIn(true);
      })
      .catch(() => {
        // console.error('error in sso init', err);
        // TODO: Display user alert, login request critical fail!
        // eslint-disable-next-line
        window.sso.logout();
      });
  }, [history, location.pathname, config]);

  if (!loggedIn) {
    return <div>Redirect...</div>;
  }

  return <div>{children}</div>;
};

export default withConfig(AuthCheck as any);
