import React, { FC } from 'react';

import { Button, Text, ButtonGroup } from '@blueprintjs/core';
import oh from 'output-helpers';
import './DisruptionDetailsStyle.css';
import text from '../text';
import { ReactComponent as IconRoadWorks } from '../img/disruptions/road_work.svg';
import { ReactComponent as IconLimited } from '../img/disruptions/limited.svg';
import { ReactComponent as IconBlocked } from '../img/disruptions/blocked.svg';

interface DisruptionDetailsProps {
  disruption: Disruption;
  onRemove: (id: number) => void;
  onEdit: () => void;
}

const getIcon = (disruption_type: DisruptionType): any => {
  switch (disruption_type) {
    case 'road_works':
      return <IconRoadWorks />;
    case 'limited':
      return <IconLimited />;
    case 'blocked':
      return <IconBlocked />;
    default: {
      return <IconBlocked />;
    }
  }
};

const DisruptionDetails: FC<DisruptionDetailsProps> = ({
  disruption,
  onRemove,
  onEdit,
}) => {
  return (
    <div className="Bubble DisruptionDetails">
      <div className="BubbleHeader --Small">
        <div className="BubbleGraphics">{getIcon(disruption.type)}</div>
        <p className="BubbleTitle">{disruption.title}</p>
      </div>
      <div className="BubbleContent">
        <p className="Pill Inline">
          {oh.formatDateAsString(disruption.start, 'YYYY-MM-DD HH:mm')}
        </p>
        <p className="PillDivider">-</p>
        <p className="Pill Inline">
          {oh.formatDateAsString(disruption.end, 'YYYY-MM-DD HH:mm')}
        </p>
        <Text className="PreWrap">{disruption.description}</Text>
        <ButtonGroup fill>
          <Button intent="danger" onClick={() => onRemove(disruption.id)}>
            {text('remove')}
          </Button>
          <Button onClick={() => onEdit()}>{text('edit')}</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default DisruptionDetails;
