import React, { FC } from 'react';

import './SearchStyle.css';
import SingleInputSearch from './SingleInputSearch';

interface SearchProps {
  map_funcs: ExportedFuncs;
  setSearchResultPath: React.Dispatch<
    React.SetStateAction<SearchResultsForMap | undefined>
  >;
}

const Search: FC<SearchProps> = ({ map_funcs, setSearchResultPath }) => {
  return (
    <div className="SearchContainer">
      <div className="SearchInputsContainer">
        <SingleInputSearch
          map_funcs={map_funcs}
          onClearSearch={() => setSearchResultPath(undefined)}
          setSearchResultPath={setSearchResultPath}
        />
      </div>
    </div>
  );
};

export default Search;
