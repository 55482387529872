import { Config } from '../config';
import { DisruptionAction } from '../MainPanel';
import { convertDisruptionFromApi } from './converters';

interface WsCtx {
  ws: WebSocket | null;
  store: React.Dispatch<DisruptionAction> | null;
  ping_timeout: any;
  token: string | null;
}
type DisruptionMsgType = 'PING' | 'UPDATE' | 'REMOVE' | 'AUTH';
interface DisruptionMsgBase {
  type: DisruptionMsgType;
}
interface DisruptionMsgUpdate extends DisruptionMsgBase {
  type: 'UPDATE';
  payload: DisruptionFromApi[];
}
interface DisruptionMsgRemove extends DisruptionMsgBase {
  type: 'REMOVE';
  /** Disruption ID. */
  payload: number[];
}
interface DisruptionMsgPing extends DisruptionMsgBase {
  type: 'PING';
}
interface DisruptionMsgAuth extends DisruptionMsgBase {
  type: 'AUTH';
  payload: {
    success: boolean;
    message?: string;
  };
}

type DisruptionMsg =
  | DisruptionMsgRemove
  | DisruptionMsgUpdate
  | DisruptionMsgPing
  | DisruptionMsgAuth;

let reconnect_delay = 5000;
const reconnect_delay_max = 30000;
let reconnect_timeout: any = undefined;
let authenticated = false;

const ctx: WsCtx = {
  ws: null,
  store: null,
  ping_timeout: 0,
  token: null,
};

const heartbeat = (): void => {
  if (ctx.ping_timeout) {
    clearTimeout(ctx.ping_timeout);
  }

  ctx.ping_timeout = setTimeout(() => {
    ctx.ws && ctx.ws.close();
  }, 30000 + 2000);
};

const onping = (): void => {
  if (!ctx.ws || ctx.ws.readyState !== ctx.ws.OPEN) {
    return;
  }
  heartbeat();
  ctx.ws.send(JSON.stringify({ type: 'PONG' }));
};

const connectWebSocket = (uri: string): void => {
  if (!ctx.ws) {
    //TODO: Maybe add a spinner? Which should be triggered here.
  }
  ctx.ws = new WebSocket(uri);
  ctx.ws.onopen = () => {
    heartbeat();
    if (ctx.token) {
      authenticate(ctx.token);
    }
  };

  ctx.ws.onmessage = (event: MessageEvent): any => {
    const msg: DisruptionMsg = JSON.parse(event.data);

    if (
      typeof msg !== 'object' ||
      !Object.prototype.hasOwnProperty.call(msg, 'type') ||
      !msg.type
    ) {
      console.warn('Invalid message recieved on disruption socket.');
      return;
    }
    if (!ctx.store) {
      console.error('Recieved disruption message before store init. Ignoring.');
      return;
    }

    const type = msg.type;

    switch (msg.type) {
      case 'PING': {
        onping();
        return;
      }
      case 'UPDATE': {
        if (
          !Object.prototype.hasOwnProperty.call(msg, 'payload') ||
          !msg.payload
        ) {
          console.error("Missing payload prop on disruption 'update' message.");
          //TODO: Verify body contents?
          return;
        }
        ctx.store({
          type: 'update',
          payload: msg.payload.map(convertDisruptionFromApi),
        });
        return;
      }
      case 'REMOVE': {
        if (
          !Object.prototype.hasOwnProperty.call(msg, 'payload') ||
          !msg.payload
        ) {
          console.error("Missing payload prop on disruption 'remove' message.");
          //TODO: Verify payload contents?
          return;
        }
        ctx.store({ type: 'remove', payload: msg.payload });
        return;
      }
      case 'AUTH': {
        authenticated = msg.payload.success;
        return;
      }
      default: {
        console.error(
          "Invalid message type recieved on disuption socket: '" + type + "'.",
        );
        return;
      }
    }
  };

  ctx.ws.onclose = () => {
    reconnect_timeout && clearTimeout(reconnect_timeout);
    reconnect_timeout = setTimeout(
      () => connectWebSocket(uri),
      reconnect_delay,
    );
    reconnect_delay =
      reconnect_delay >= reconnect_delay_max
        ? reconnect_delay_max
        : reconnect_delay + 1000;
  };
  ctx.ws.onerror = (err) => {
    console.error(err);
    ctx.ws && ctx.ws.close();
    reconnect_timeout && clearTimeout(reconnect_timeout);
    reconnect_timeout = setTimeout(
      () => connectWebSocket(uri),
      reconnect_delay,
    );
    reconnect_delay =
      reconnect_delay >= reconnect_delay_max
        ? reconnect_delay_max
        : reconnect_delay + 1000;
  };
};

export const addDisruption = (
  disruption: Omit<DisruptionFromApi, 'id'>,
): void => {
  if (!ctx.ws) {
    console.error('Tried to create without socket.');
    return;
  }
  if (!authenticated) {
    console.error('Tried to add without being authenticated.');
    return;
  }

  ctx.ws.send(JSON.stringify({ type: 'INSERT', payload: disruption }));
};

export const editDisruption = (disruption: DisruptionFromApi): void => {
  if (!ctx.ws) {
    console.error('Tried to edit without socked.');
    return;
  }
  if (!authenticated) {
    console.error('Tried to edit without being authenticated.');
    return;
  }

  ctx.ws.send(JSON.stringify({ type: 'EDIT', payload: disruption }));
};

export const removeDisruption = (id_arr: number[]): void => {
  if (!ctx.ws) {
    console.error('Tried to remove without socket.');
    return;
  }

  if (!authenticated) {
    console.error('Tried to remove without being authenticated.');
    return;
  }

  ctx.ws.send(JSON.stringify({ type: 'REMOVE', payload: id_arr }));
};

export const prepare = (
  store: React.Dispatch<DisruptionAction>,
  token: string,
): void => {
  ctx.store = store;
  ctx.token = token;
};

export const start = (config: Config): void => {
  if (ctx.ws) {
    console.warn(
      'Tried to start disruption websocket when a socket is already started.',
    );
    return;
  }
  connectWebSocket(config.notifications.disruptions.wss);
};

export const authenticate = (token: string): void => {
  if (!ctx.ws) {
    console.error('Tried to authenticate before socket exists.');
    return;
  }

  ctx.token = token;
  ctx.ws.send(JSON.stringify({ type: 'AUTH', payload: { token: token } }));
};
