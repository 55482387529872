import oh, { TypedDictionary } from 'output-helpers';

export type TextKeys =
  | 'address'
  | 'all'
  | 'blocked'
  | 'cancel'
  | 'capacity'
  | 'car'
  | 'close'
  | 'create_disruption'
  | 'create'
  | 'description'
  | 'disruptions'
  | 'dropoff'
  | 'end'
  | 'find'
  | 'group_display_text'
  | 'invalid_address'
  | 'invalid_vehicle_id'
  | 'loading'
  | 'limited'
  | 'minibus_w_bed'
  | 'minibus'
  | 'no_bookings'
  | 'pick_location_help_text'
  | 'pickup'
  | 'remove'
  | 'edit'
  | 'required'
  | 'road_works'
  | 'stairclimber'
  | 'start'
  | 'title'
  | 'type'
  | 'unscheduled'
  | 'vehicle_id'
  | 'vehicles'
  | 'save';

const dictionary: TypedDictionary<Dictionary> = {
  'sv-SE': {
    address: 'adress',
    all: 'alla',
    blocked: 'blockerad',
    cancel: 'avbryt',
    capacity: 'kapacitet',
    car: 'personbil',
    close: 'stäng',
    create_disruption: 'skapa störning',
    create: 'skapa',
    description: 'beskrivning',
    disruptions: 'störningar',
    dropoff: 'lämna',
    find: 'hitta',
    end: 'slut',
    group_display_text: 'serie',
    invalid_address: 'ogiltig address',
    invalid_vehicle_id: 'ogiltigt fordonsid',
    loading: 'laddar',
    minibus_w_bed: 'minibuss ligg',
    minibus: 'minibuss',
    no_bookings: 'inga bokningar',
    pick_location_help_text: `Använd sök eller klicka på kartan för att välja en plats för störningen. Det är möjligt att flytta på markören efter den placerats.\n
    Klicka sedan på knappen nedan för att öppna skapa-dialogen.`,
    pickup: 'hämta',
    remove: 'ta bort',
    edit: 'redigera',
    required: 'krävs',
    limited: 'begränsad',
    road_works: 'vägarbete',
    stairclimber: 'trappklättrare',
    start: 'start',
    title: 'titel',
    type: 'typ',
    unscheduled: 'obokade',
    vehicle_id: 'fordonsid',
    vehicles: 'fordon',
    save: 'spara',
  },
  'en-US': {
    address: 'address',
    all: 'all',
    blocked: 'blocked',
    cancel: 'cancel',
    capacity: 'capacity',
    car: 'car',
    find: 'find',
    close: 'close',
    create_disruption: 'create disruption',
    create: 'create',
    description: 'description',
    disruptions: 'disruptions',
    dropoff: 'dropoff',
    end: 'end',
    group_display_text: 'series',
    invalid_address: 'invalid address',
    invalid_vehicle_id: 'invalid vehicle id',
    loading: 'loading',
    minibus_w_bed: 'minibus bed',
    minibus: 'minibus',
    no_bookings: 'no bookings',
    pick_location_help_text: `Use the search or click on the map to select a location for the disruption. It is possible to drag and move the marker after it has been placed.\n
    Click the button below to open the disruption creation form.`,
    pickup: 'pickup',
    remove: 'remove',
    edit: 'edit',
    required: 'required',
    limited: 'limited',
    road_works: 'road works',
    stairclimber: 'stairclimber',
    start: 'start',
    title: 'title',
    type: 'type',
    unscheduled: 'unscheduled',
    vehicle_id: 'vehicle id',
    vehicles: 'vehicles',
    save: 'save',
  },
};

const translate = (key: keyof Dictionary, capitalize = true): string => {
  return String(oh.translateTyped<Dictionary>(key, capitalize));
};

export type Dictionary = { [key in TextKeys]: string };
export { dictionary, translate };
