import React, { FC, useState } from 'react';
import {
  Button,
  ButtonGroup,
  InputGroup,
  FormGroup,
  TextArea,
  HTMLSelect,
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import MomentLocaleUtils from 'react-day-picker/moment';

import './EditDisruptionFormStyle.css';
import text from '../text';

type onEdit = (data: Disruption) => void;
interface EditDisruptionFormProps {
  onCancel: () => void;
  onEdit: onEdit;
  to_edit: Disruption;
}

const submit = (
  onEdit: onEdit,
  id: number,
  title: string,
  description: string,
  type: DisruptionType,
  start: Date,
  end: Date,
  location: import('google-maps-wrapper').GMW_LatLngLiteral,
): void => {
  onEdit({
    id,
    title,
    description,
    type,
    start,
    end,
    location,
  });
};

const EditDisruptionForm: FC<EditDisruptionFormProps> = ({
  onCancel,
  onEdit,
  to_edit,
}) => {
  const [title, setTitle] = useState<string>(to_edit.title);
  const [description, setDescription] = useState<string>(to_edit.description);
  const [type, setType] = useState<DisruptionType>(to_edit.type);
  const [start, setStart] = useState<Date>(to_edit.start);
  const [end, setEnd] = useState<Date>(to_edit.end);
  return (
    <div className="Bubble EditForm">
      <div className="BubbleContent">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (title.length > 2) {
              submit(
                onEdit,
                to_edit.id,
                title,
                description,
                type,
                start,
                end,
                to_edit.location,
              );
            }
          }}
        >
          <ButtonGroup vertical fill>
            <FormGroup
              label={text('title')}
              labelInfo={text('required', false)}
            >
              <InputGroup
                leftIcon="tag"
                value={title}
                //Following disable because onChange typing seems wrong, it claims 'ev' is any.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                onChange={(ev: any) => setTitle(ev.target.value)}
              />
            </FormGroup>
            <FormGroup
              label={text('description')}
              labelInfo={text('required', false)}
            >
              <TextArea
                value={description}
                className="bp3-fill"
                //Following disable because onChange typing seems wrong, it claims 'ev' is any.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                onChange={(ev: any) => setDescription(ev.target.value)}
              />
            </FormGroup>
            <FormGroup label={text('type')} labelInfo={text('required', false)}>
              <HTMLSelect
                className="bp3-fill"
                value={type}
                //Following disable because onChange typing seems wrong, it claims 'ev' is any.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                onChange={(ev: any) => setType(ev.target.value)}
              >
                <option value="road_works">{text('road_works')}</option>
                <option value="limited">{text('limited')}</option>
                <option value="blocked">{text('blocked')}</option>
              </HTMLSelect>
            </FormGroup>
            <ButtonGroup fill>
              <FormGroup
                label={text('start')}
                labelInfo={text('required', false)}
              >
                <DatePicker
                  value={start}
                  localeUtils={MomentLocaleUtils}
                  locale="sv-SE"
                  timePrecision="minute"
                  canClearSelection={false}
                  onChange={(new_start) => {
                    setStart(new_start);
                  }}
                />
              </FormGroup>
              <FormGroup
                label={text('end')}
                labelInfo={text('required', false)}
              >
                <DatePicker
                  value={end}
                  localeUtils={MomentLocaleUtils}
                  locale="sv-SE"
                  timePrecision="minute"
                  canClearSelection={false}
                  onChange={(new_end) => {
                    setEnd(new_end);
                  }}
                />
              </FormGroup>
            </ButtonGroup>
          </ButtonGroup>
          <ButtonGroup fill>
            <Button intent="warning" type="button" onClick={() => onCancel()}>
              {text('cancel')}
            </Button>
            <Button
              intent="primary"
              type="button"
              disabled={title.length < 3}
              onClick={() =>
                submit(
                  onEdit,
                  to_edit.id,
                  title,
                  description,
                  type,
                  start,
                  end,
                  to_edit.location,
                )
              }
            >
              {text('save')}
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
};

export default EditDisruptionForm;
