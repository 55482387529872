export interface Config {
  api: {
    pass: Record<'uri', string>;
    avl: Record<'uri', string>;
    sso: Record<'uri' | 'login_uri', string>;
  };
  /** URIs to notification servers (websocket targets). */
  notifications: {
    disruptions: Record<'wss', string>;
  };
  map: {
    center: import('google-maps-wrapper').GMW_LatLngLiteral;
    zoom: number;
    style?: {
      [key: string]: any;
    }[];
  };
  default_language: 'sv-SE' | 'en-US';
  service_name: string;
}

const config: Config = {
  api: {
    pass: {
      uri: '',
    },
    avl: {
      uri: '',
    },
    sso: {
      uri: 'https://sso.allbin.se/v2',
      login_uri: 'https://login.allbin.se/v2',
    },
  },
  service_name: 'disruption_admin_test',
  default_language: 'sv-SE',
  notifications: {
    disruptions: {
      wss: 'wss://ntd-disruption-api.test.allbin.se',
    },
  },
  map: {
    center: { lat: 55.7, lng: 13.6 },
    zoom: 10,
    style: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#F5F5F5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#BDBDBD',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EEEEEE',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.business',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#E5E5E5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#D3D9E9',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#D3D9E9',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#DADADA',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#E5E5E5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#EEEEEE',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#AADDFF',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9E9E9E',
          },
        ],
      },
    ],
  },
};

export default config;
